
export const parseData = (inputData) => {
    const outputData = [];

    for (let i = 0; i < inputData[0].values.length; i++) {
        const record = {};
        for (const entry of inputData) {
            record[entry.key] = entry.values[i];
        }
        outputData.push(record);
    }

    return outputData
}

export const dataStructuring = (sorted_data, label_key, metric_key, series_key, other_metric_key = null) => {
    let series_list = [...new Set(sorted_data.map((ele) => ele[series_key]))]
    let label_list = [...new Set(sorted_data.map((ele) => ele[label_key]))]

    let main_metric_data = {}
    let other_metric_data = {}

    for (let item of sorted_data) {
        if (!(item[series_key] in main_metric_data)) main_metric_data[item[series_key]] = {};
        main_metric_data[item[series_key]][item[label_key]] = item[metric_key]

        if (other_metric_key) {
            if (!(item[series_key] in other_metric_data)) other_metric_data[item[series_key]] = {};
            other_metric_data[item[series_key]][item[label_key]] = item[other_metric_key]
        }
    }



    let data_mapping = {}

    if (other_metric_key) {
        data_mapping[other_metric_key] = {}
        data_mapping[metric_key] = {}
    }

    for (let series of series_list) {
        if (other_metric_key) {
            let other_list = []
            for (let label of label_list) {
                other_list.push(other_metric_data?.[series]?.[label] ? other_metric_data[series][label] : null)
            }
            data_mapping[other_metric_key][series] = other_list

            let main_list = []
            for (let label of label_list) {
                main_list.push(main_metric_data?.[series]?.[label] ? main_metric_data[series][label] : null)
            }
            data_mapping[metric_key][series] = main_list

        } else {

            let list = []
            for (let label of label_list) {
                list.push(main_metric_data?.[series]?.[label] ? main_metric_data[series][label] : null)
            }
            data_mapping[series] = list
        }

    }

    return { x_fields: label_list, series_fields: series_list, data_mapping: data_mapping }
}

