import React, { useContext, useEffect, useRef, useState } from 'react';
import { Switch } from 'antd';
import { Chart, LinearScale, CategoryScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { colorData } from '../../utils/colors.js'
import { Heading } from '../label/Heading.js';
import { dataStructuring } from '../../utils/parseData.js';
import { NoDataError } from './NoDataError.js';
import "./styles/Charts.css"
import { ScrollableLegend } from './ScrollableLegend.js';
import { DataContext } from '../../context/ContextProvider.js';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const calculatePercentage = (value, compare_value) => {
    if (value === null || compare_value === null) return null
    if (compare_value === 0) return value
    return ((value - compare_value) / compare_value) * 100

}

export const LineCharts = ({ data, firstRender }) => {

    const { markingCityDict } = useContext(DataContext)

    const hasBolt = useRef(true)
    const lineChartEl = useRef(null)
    const [, forceUpdate] = useState()
    const [showPercentage, setShowPercentage] = useState(false)

    const structured_data = dataStructuring(data.chart_data, data.dimensions.x.field, data.dimensions.y.field, data.dimensions.series_field)
    const curCity = useRef(() => {
        let selectedFilters = JSON.parse(localStorage.getItem('defaultSelectedFilters'))
        return selectedFilters.city.options[0].value
    })
    const togglePercentage = (checked) => { setShowPercentage(checked) };


    let bolt;
    for (let key in structured_data.data_mapping) {
        if (key.toLowerCase().includes("bolt")) {
            bolt = structured_data.data_mapping[key]
            break;
        }
    }

    hasBolt.current = bolt !== undefined ? true : false

    const formattedData = {
        labels: structured_data.x_fields,
        datasets: structured_data.series_fields.map((series_key) => {
            let color = "#CEFA70";
            if (series_key !== null) {
                color = colorData.find((ele) => series_key.toLowerCase().includes(ele.Rival.toLowerCase())) ? colorData.find((ele) => series_key.toLowerCase().includes(ele.Rival.toLowerCase()))["Color"] : "#CEFA70"
            }

            return {
                label: `${series_key}`,
                data: structured_data.data_mapping[series_key].map((ele, index) => showPercentage && bolt !== undefined ? calculatePercentage(ele, bolt[index]) : ele),
                borderColor: color,
                backgroundColor: color,
            }
        })
    }

    const xLabelsRef = useRef(formattedData.labels);

    const options = {
        layout: { padding: { top: -15 } },
        responsive: true,
        aspectRatio: 4.5,
        interaction: { mode: 'index', intersect: false },
        scales: {
            x: {
                display: true,
                offset: 10,
                title: {
                    display: data.dimensions.x.title.display,
                    text: data.dimensions.x.title.text
                },
                grid: { display: false }
            },
            y: {
                display: true,
                title: {
                    display: data.dimensions.y.title.display,
                    text: data.dimensions.y.title.text
                },
                grid: { display: false }
            },
        },
        elements: { point: { radius: 1 }, line: { borderWidth: 3 } },
        plugins: {
            decimation: { enabled: true, algorithm: 'lttb' },
            legend: { display: false },
            // tooltip: {
            //     mode: 'index',
            //     callbacks: {
            //         label: function (context) {
            //             return context.dataset.label + ': ' + context.dataset.data[context.dataIndex] + '%';
            //         }
            //     }
            // }
        },
    };

    const plugins = [
        {
            beforeDraw: (chart) => {
                // eslint-disable-next-line no-underscore-dangle
                const xAxis = chart.scales['x'];
                if (xAxis) {
                    const { ctx } = chart;

                    const cityDict = markingCityDict

                    let targetValue;

                    if (curCity.current in cityDict) {
                        let date_hour = cityDict[curCity.current]
                        let date = cityDict[curCity.current].split(" ")[0]

                        if (xLabelsRef.current.includes(date_hour)) {
                            targetValue = date_hour
                        } else if (xLabelsRef.current.includes(date)) {
                            targetValue = date
                        }
                    }

                    let xPos
                    if (targetValue === undefined) {
                        xPos = undefined
                    } else {
                        xPos = xAxis.getPixelForValue(targetValue);
                    }


                    const topY = chart.scales.y.top;
                    const bottomY = chart.scales.y.bottom;

                    // draw vertical line
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(xPos, topY);
                    ctx.lineTo(xPos, bottomY);
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = 'black';
                    ctx.stroke();
                    ctx.restore();

                    // write TODAY
                    ctx.textAlign = 'center';
                    ctx.fillText(targetValue, xPos, topY - 1);

                    lineChartEl.current.update();
                }
            },
        },
    ];

    useEffect(() => {
        xLabelsRef.current = formattedData.labels

        let selectedFilters = JSON.parse(localStorage.getItem('defaultSelectedFilters'))
        curCity.current = selectedFilters.city.options[0].value

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])


    return (
        <>
            <Heading label={ data.label } fields={ data.fields } />
            <div style={ { paddingTop: "20px", height: "100%" } }>
                { data.chart_data.length > 0 ? <>
                    <ScrollableLegend datasets={ formattedData.datasets } chartEl={ lineChartEl } forceUpdate={ forceUpdate } />
                    <Line ref={ (c) => lineChartEl.current = c } options={ options } data={ formattedData } plugins={ plugins } />
                    <div className='toggle_btn'>
                        <p>% Wise</p>
                        <div >
                            <Switch onClick={ togglePercentage } disabled={ !hasBolt.current } />
                        </div>
                    </div> </> : <NoDataError firstRender={ firstRender } /> }
            </div>
        </>
    );
}


export const LineChart2 = ({ data, firstRender }) => {

    const { markingCityDict } = useContext(DataContext)

    const hasBolt = useRef(true)
    const lineChartEl = useRef(null)
    const [, forceUpdate] = useState()
    const [showPercentage, setShowPercentage] = useState(false)

    const structured_data = dataStructuring(data.chart_data, data.dimensions.x.field, data.dimensions.y.field, data.dimensions.series_field, "min_fare")


    const curCity = useRef(() => {
        let selectedFilters = JSON.parse(localStorage.getItem('defaultSelectedFilters'))
        return selectedFilters.city.options[0].value
    })
    const togglePercentage = (checked) => { setShowPercentage(checked) };


    let bolt;
    for (let key in structured_data.data_mapping[data.dimensions.y.field]) {
        if (key.toLowerCase().includes("bolt")) {
            bolt = structured_data.data_mapping[data.dimensions.y.field][key]
            break;
        }
    }

    hasBolt.current = bolt !== undefined ? true : false


    const formattedData = {
        labels: structured_data.x_fields,
        datasets: structured_data.series_fields.map((series_key) => {
            let color = "#CEFA70";
            if (series_key !== null) {
                color = colorData.find((ele) => series_key.toLowerCase().includes(ele.Rival.toLowerCase())) ? colorData.find((ele) => series_key.toLowerCase().includes(ele.Rival.toLowerCase()))["Color"] : "#CEFA70"
            }

            return [
                {
                    label: `${series_key}`,
                    data: structured_data.data_mapping[data.dimensions.y.field][series_key].map((ele, index) => showPercentage && bolt !== undefined ? calculatePercentage(ele, bolt[index]) : ele),
                    borderColor: color,
                    backgroundColor: color,
                    borderDash: []
                },
                {
                    label: `${series_key}-min`,
                    data: structured_data.data_mapping["min_fare"][series_key].map((ele, index) => showPercentage && bolt !== undefined ? calculatePercentage(ele, bolt[index]) : ele),
                    borderColor: color,
                    backgroundColor: color,
                    borderDash: [5, 5]
                }
            ]
        }).flat()
    }

    const xLabelsRef = useRef(formattedData.labels);

    const options = {
        layout: { padding: { top: -15 } },
        responsive: true,
        aspectRatio: 4.5,
        interaction: { mode: 'index', intersect: false },
        scales: {
            x: {
                display: true,
                offset: 10,
                title: {
                    display: data.dimensions.x.title.display,
                    text: data.dimensions.x.title.text
                },
                grid: { display: false }
            },
            y: {
                display: true,
                title: {
                    display: data.dimensions.y.title.display,
                    text: data.dimensions.y.title.text
                },
                grid: { display: false }
            },
        },
        elements: { point: { radius: 1 }, line: { borderWidth: 3 } },
        plugins: {
            decimation: { enabled: true, algorithm: 'lttb' },
            legend: { display: false },
            // tooltip: {
            //     mode: 'index',
            //     callbacks: {
            //         label: function (context) {
            //             return context.dataset.label + ': ' + context.dataset.data[context.dataIndex] + '%';
            //         }
            //     }
            // }
        },
    };

    const plugins = [
        {
            beforeDraw: (chart) => {
                // eslint-disable-next-line no-underscore-dangle
                const xAxis = chart.scales['x'];
                if (xAxis) {
                    const { ctx } = chart;

                    const cityDict = markingCityDict

                    let targetValue;

                    if (curCity.current in cityDict) {
                        let date_hour = cityDict[curCity.current]
                        let date = cityDict[curCity.current].split(" ")[0]

                        if (xLabelsRef.current.includes(date_hour)) {
                            targetValue = date_hour
                        } else if (xLabelsRef.current.includes(date)) {
                            targetValue = date
                        }
                    }

                    let xPos
                    if (targetValue === undefined) {
                        xPos = undefined
                    } else {
                        xPos = xAxis.getPixelForValue(targetValue);
                    }


                    const topY = chart.scales.y.top;
                    const bottomY = chart.scales.y.bottom;

                    // draw vertical line
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(xPos, topY);
                    ctx.lineTo(xPos, bottomY);
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = 'black';
                    ctx.stroke();
                    ctx.restore();

                    // write TODAY
                    ctx.textAlign = 'center';
                    ctx.fillText(targetValue, xPos, topY - 1);

                    lineChartEl.current.update();
                }
            },
        },
    ];

    useEffect(() => {
        xLabelsRef.current = formattedData.labels

        let selectedFilters = JSON.parse(localStorage.getItem('defaultSelectedFilters'))
        curCity.current = selectedFilters.city.options[0].value

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])


    return (
        <>
            <Heading label={ data.label } fields={ data.fields } />
            <div style={ { paddingTop: "20px", height: "100%" } }>
                { data.chart_data.length > 0 ? <>
                    <ScrollableLegend datasets={ formattedData.datasets } chartEl={ lineChartEl } forceUpdate={ forceUpdate } />
                    <Line ref={ (c) => lineChartEl.current = c } options={ options } data={ formattedData } plugins={ plugins } />
                    <div className='toggle_btn'>
                        <p>% Wise</p>
                        <div >
                            <Switch onClick={ togglePercentage } disabled={ !hasBolt.current } />
                        </div>
                    </div> </> : <NoDataError firstRender={ firstRender } /> }
            </div>
        </>
    );
}
